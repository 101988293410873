import {Navigate, Route, Routes} from 'react-router-dom'
import {useSelector} from "react-redux";
import {RootState} from "./store";
import Login from "./pages/login/Login";
import {Box, styled} from "@mui/material";
import Search from "./pages/search/Search";
import Home from "./pages/home/Home";
import ReportDialog from "./components/organisms/ReportDialog";
import TaskListDialog from './components/organisms/TaskListDialog';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja')

function App() {
    const auth = useSelector((root: RootState) => root.auth)

    return (
        <Content>
            <ReportDialog/>
            <TaskListDialog/>
            <Routes>
                <Route path={'/'} element={auth.isAuthenticated ? <Home/> : <Navigate to={'login'}/>}/>
                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/search'} element={<Search/>}/>
            </Routes>
        </Content>
    );
}

const Content = styled(Box)({
    height: '100vh'
})

export default App;
