import {SvgIcon} from "@mui/material";

const ArrowSyncCircle = () => {
    return (
        <SvgIcon viewBox={'0 0 20 20'} sx={{fontSize: '20px'}} >
            <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18ZM13.4999 10C13.776 10 13.9999 9.77614 13.9999 9.5V7.5C13.9999 7.22386 13.776 7 13.4999 7C13.2237 7 12.9999 7.22386 12.9999 7.5V8.19617C12.3877 7.18015 11.2737 6.5 10.0001 6.5C8.96342 6.5 8.03154 6.9513 7.39138 7.66654C7.20721 7.87231 7.22472 8.1884 7.43049 8.37257C7.63625 8.55673 7.95235 8.53922 8.13651 8.33346C8.59499 7.8212 9.25968 7.5 10.0001 7.5C11.0245 7.5 11.9062 8.11648 12.2922 9H11.4999C11.2237 9 10.9999 9.22386 10.9999 9.5C10.9999 9.77614 11.2237 10 11.4999 10H13.4999ZM6.00012 10.5V12.5C6.00012 12.7761 6.22398 13 6.50012 13C6.77626 13 7.00012 12.7761 7.00012 12.5V11.8037C7.61231 12.8198 8.7263 13.5 10.0001 13.5C11.0367 13.5 11.9686 13.0487 12.6087 12.3335C12.7929 12.1277 12.7754 11.8116 12.5696 11.6274C12.3639 11.4433 12.0478 11.4608 11.8636 11.6665C11.4051 12.1788 10.7404 12.5 10.0001 12.5C8.97557 12.5 8.09394 11.8835 7.70793 11H8.50012C8.77627 11 9.00012 10.7761 9.00012 10.5C9.00012 10.2239 8.77627 10 8.50012 10H6.50012C6.22398 10 6.00012 10.2239 6.00012 10.5Z" fill="#F97316"/>
        </SvgIcon>
    )
}

export default ArrowSyncCircle
