import {SvgIcon, SvgIconProps, SxProps} from "@mui/material";
import React from "react";
import colors from "../../styles/colors";

type ArrowProps = {
    sx?: SxProps,
    fill?: keyof typeof colors
} & SvgIconProps
const Arrow: React.FC<ArrowProps> = ({ fill = 'cool-gray-900', sx, ...props }) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 16 16'} sx={sx} >
            <path fill={colors[fill]} d="M6.99968 2.75C6.99968 2.33579 7.33547 2 7.74968 2H13.2497C13.6639 2 13.9997 2.33579 13.9997 2.75V8.25C13.9997 8.66421 13.6639 9 13.2497 9C12.8355 9 12.4997 8.66421 12.4997 8.25V4.5607L3.28034 13.7803C2.98745 14.0732 2.51258 14.0732 2.21968 13.7803C1.92678 13.4874 1.92677 13.0126 2.21966 12.7197L11.4391 3.5H7.74968C7.33547 3.5 6.99968 3.16421 6.99968 2.75Z" />
        </SvgIcon>
    )
}

export default Arrow