import React from "react";
import {Stack, Typography} from "@mui/material";
import colors from "../../styles/colors";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../store";
import {ViewType} from "../../models/home";

const Switch2 = (props: { direction: String }) => {

    return (
        <Stack
            position={'fixed'}
            sx={{
                opacity: 1,
                transform: 'translateX(-50%)',
                background: 'white',
                transition: 'all .5s ease',
                ...(props.direction === 'down' && {
                    transform: 'translate(-50%, 64px)',
                    opacity: 0
                })
            }}
            bottom={'16px'}
            left={'50%'}
            direction={'row'}
            padding={'3px'}
            height={'38px'}
            boxShadow={'0px 0px 0px 1px rgba(0, 0, 0, 0.01), 0px -4px 24px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.06)'}
            borderRadius={'99px'}>
            <SwitchItem label={'月'} type={ViewType.MONTH}/>
            <SwitchItem label={'3日間'} type={ViewType.THREEDAYS}/>
            <SwitchItem label={'日'} type={ViewType.DAY}/>
            <SwitchItem label={'List'} type={ViewType.LIST}/>
        </Stack>
    )
}

type SwitchItemProps2 = {
    label: string,
    type: ViewType
}

const SwitchItem: React.FC<SwitchItemProps2> = ({label, type}) => {
    const viewType = useSelector((root: RootState) => root.home.viewType)
    const dispatch = useDispatch<Dispatch>()
    const selected = viewType === type

    const updateViewType = () => {
        dispatch.home.setViewType(type)
    }

    return (
        <Stack
            onClick={updateViewType}
            width={'84.25px'}
            height={'32px'}
            borderRadius={'99px'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                background: selected ? colors["cool-gray-300"] : 'whites'
            }}>
            <Typography
                variant={'sm'}
                fontWeight={700}
                color={selected ? colors["cool-gray-900"] : colors["cool-gray-500"]}>
                {label}
            </Typography>
        </Stack>
    )
}

export default Switch2