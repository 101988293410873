import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import {getDayReportsList} from "../services/apis/api.service";

export type TasksStateType = {
    taskList: any[],
    currentTask?: any
}

const initialState: TasksStateType = {
    taskList: [],
    currentTask: undefined
}

export const tasks = createModel<RootModel>()({
    name: 'tasks',
    state: initialState,
    reducers: {
        setTaskList(state, taskList: typeof initialState.taskList) {
            state.taskList = taskList
        },
        setCurrentTask(state, currentTask: typeof initialState.currentTask) {
            state.currentTask = currentTask
        }
    },

    effects: dispatch => {
        const effects: any = {}

        effects.loadTaskList = (_: any, rootState: any) => {
            if (rootState.home.currentTab !== 0) return
            getDayReportsList()
                .then( data => {
                    dispatch.tasks.setTaskList(data)
                })
            dispatch.tasks.setCurrentTask(undefined)
        }

        return effects
    }
})