import {createModel} from "@rematch/core";
import {RootModel} from "./index";

export type SearchStateType = {
    searchKey: string
}

const initialState: SearchStateType = {
    searchKey: ''
}

export const search = createModel<RootModel>()({
    name: 'search',
    state: initialState,
    reducers: {
        changeSearchKeyState(state, searchKey: string) {
            state.searchKey = searchKey
        }
    },

    effects: dispatch => {
        const effects: any = {}

        effects.changeSearchKey = (keyword: string) => {
            dispatch.search.changeSearchKeyState(keyword)
        }

        return effects
    }
})