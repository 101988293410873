import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../store";
import {Button, Stack, TextField} from "@mui/material";
import Image from "../../components/atoms/Image";
import logo from "../../images/logo.svg";
import Eye from "../../components/icons/Eye";
import EyeOff from "../../components/icons/EyeOff";
import {useNavigate} from "react-router-dom";


const Login: React.FC<any> = () => {
    const login = useSelector((root: RootState) => {
        return root.login
    })
    // const value = useSelector((root: RootState) => {
    //     if (Math.random() > .5)
    //         return root.login?.username
    //     else return root.login?.password
    // })
    // const root = useSelector(root => root)
    const dispatch = useDispatch<Dispatch>()
    const navigate = useNavigate()

    const handleChange = (key: string) => (e: any) => {
        dispatch.login.setField({
            value: e.target.value,
            key
        })
    }

    const handleClick = async () => {
        const status = await dispatch.login.submitLogin(null)

        if (status) {
            navigate('/')
        }
    }

    return (
        <Stack
            direction={'column'}
            gap={'24px'}
            alignItems={'center'}
            padding={'32px 24px 0px 24px'}
        >
            <Image src={logo} sx={{marginBottom: '8px'}} />
            <TextField
                variant={'outlined'}
                label={'社員番号'}
                onChange={handleChange('username')}
                value={login.username}
                fullWidth/>
            <TextField
                variant={'outlined'}
                label={'パスワード'}
                onChange={handleChange('password')}
                value={login.password}
                InputProps={{
                    type: login.passwordInputType,
                    endAdornment: login.passwordInputType === 'password' ?
                        <Eye sx={{
                            fontSize: '20px',
                            cursor: 'pointer'
                        }} fill={'cool-gray-400'} onClick={() => dispatch.login.setInputType('text')}/> :
                        <EyeOff sx={{
                            fontSize: '20px',
                            cursor: 'pointer'
                        }} fill={'cool-gray-400'} onClick={() => dispatch.login.setInputType('password')}/>
                }}
                fullWidth/>

            <Button variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={handleClick}>ログイン</Button>
        </Stack>
    )
}

export default Login