import {styled} from "@mui/material";
import {ImgHTMLAttributes} from "react";

type ImageProps = {
    src?: any,
    className?: string,
    alt?: string
} & ImgHTMLAttributes<any>
const Image = styled(({ className, ...props }: ImageProps) => (
    <img {...props} className={className} src={props.src ?? 'https://picsum.photos/2000/2000'} alt={props.alt ?? 'image'}/>
))<ImageProps>({})

export default Image
