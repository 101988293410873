import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogContent, Stack,
    styled,
    TextField,
    Typography
} from "@mui/material";
import * as yup from 'yup'
import {MobileDatePicker, MobileTimePicker} from "@mui/x-date-pickers";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../store";
import Dismiss from "../icons/Dismiss";
import colors from "../../styles/colors";
import Calendar from "../icons/Calendar";
import Clock from "../icons/Clock";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {putDayReport} from "../../services/apis/api.service";
import {ReportParam} from "../../services/types";
import dayjs from "dayjs";

interface ReportFormInput {
    matterNumber: string
    department: number
    classification: number
    content: number
    contentDate: string
    contentTime: number
    manHour: number
    rate: number
}

const schema = yup.object().shape({
    department: yup.number().typeError('Must be a number').required("Enter department"),
    classification: yup.number().typeError('Must be a number').required("Enter classification"),
    content: yup.number().typeError('Must be a number').required("Enter content"),
    manHour: yup.number().required("Enter hour"),
    rate: yup.number().typeError('Must be a number')
        .required("Enter achievement rate")
})

const ReportDialog: React.FC<any> = () => {
    const currentTask = useSelector((root: RootState) => root.tasks?.currentTask)
    const code = useSelector((root : RootState)=> root.auth.user?.code)
    const open = useSelector((root: RootState) => root.home?.openReportDialog)
    const isViewMode = useSelector((root: RootState) => root.home!.viewMode)
    const dispatch = useDispatch<Dispatch>()
    // for temporary
    const [date, setDate] = useState<Date | null>(null)
    const [time, setTime] = useState<Date | null>(null)

    const handleClose = () => {
        dispatch.home.closeReportDialog()
        // @ts-ignore
        window.reloadData?.()
    }

    const dateHandleChange = (newValue: Date | null) => {
        setDate(newValue);
    }

    const timeHandleChange = (newValue: Date | null) => {
        setTime(newValue);
    }

    const {register, handleSubmit, formState , setValue} = useForm<ReportFormInput>({
        resolver: yupResolver(schema),
        mode: "onChange"
    })

    useEffect( () => {
        setValue('department', currentTask?.workdepartment)
        setValue('classification', currentTask?.workclassification)
        setValue('content', currentTask?.workcontents)
        setDate(currentTask?.workdate)
        setTime(currentTask?.worktime)
        setValue('manHour', currentTask?.worktime)
        setValue('rate', currentTask?.achievementrate)
    },[currentTask])

    const {errors} = formState

    const tempSave = () => {
        handleClose()
        // let item = {
        //     'id': tasks.currentTask?.id,
        //     'date': date?.toString(),
        //     'time': time?.toString()
        // }
        // let key = "datetime"
        // if (typeof window !== 'undefined') {
        //     let datetimes = window.localStorage.getItem(key);
        //     if (datetimes == null) {
        //         console.log("add newly datetimes")
        //         window.localStorage.setItem(key, '[]')
        //     }
        //
        //     datetimes = window.localStorage.getItem(key)
        //     if (datetimes == null) return;
        //     const dateTimesJson = JSON.parse(datetimes)
        //     console.log("modify or add ", dateTimesJson)
        //     const index = dateTimesJson.findIndex((t: any) => t.id === tasks.currentTask?.id)
        //     if (index !== -1) {
        //         dateTimesJson[index].date = date
        //         dateTimesJson[index].time = time
        //     } else {
        //         dateTimesJson.push(item)
        //     }
        //     console.log(dateTimesJson)
        //     window.localStorage.setItem(key, JSON.stringify(dateTimesJson))
        // }
    }

    const onFormSubmit: SubmitHandler<ReportFormInput> = (data) => {
        if (date == null) return
        let param: ReportParam = {
            code: code ?? 0,
            matternumber: currentTask?.matternumber,
            workdepartment: data.department,
            workclassification: data.classification,
            workcontents: data.content,
            workdate: dayjs(date).format('YYYYMMDD'),
            worktime: data.manHour,
            achievementrate: data.rate
        }
        putDayReport(param)
            .then(resp => {
                if(resp.status === 200)
                    handleClose()
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'}
                scroll={"paper"}
        >
            <StyledDialogContent>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    padding={'16px'}
                    height={'56px'}
                    sx={{background: 'white'}}>
                    <Typography
                        variant={'base'}
                        color={colors["cool-gray-900"]}
                        fontWeight={700}>
                        { isViewMode && '日報'}
                        { !isViewMode && '日報入力'}
                    </Typography>
                    <Dismiss sx={{
                        fontSize: '24px',
                        cursor: 'pointer'
                    }} fill={'cool-gray-400'} onClick={handleClose}/>
                </Stack>
                <Stack
                    direction={'column'}
                    gap={'24px'}
                    padding={'12px 16px 16px 16px'}
                    >
                    <Typography
                        variant={"sm"}
                    > { currentTask?.matternumber }</Typography>
                    <TextField
                        inputProps={{
                            readOnly: isViewMode
                        }}
                        variant={'outlined'}
                        label={'作業部門'}
                        {...register('department')}
                        error={!!errors.department}
                        fullWidth/>
                    <TextField
                        inputProps={{
                            readOnly: isViewMode
                        }}
                        variant={'outlined'}
                        label={'作業区分'}
                        {...register("classification")}
                        error={!!errors.classification}
                        fullWidth/>
                    <TextField
                        inputProps={{
                            readOnly: isViewMode
                        }}
                        variant={'outlined'}
                        label={'作業内容'}
                        {...register("content")}
                        error={!!errors.content}
                        fullWidth/>

                    {/* work content - date and time picker */}
                    <Stack
                        direction={'row'}
                        gap={'20px'}>
                        <MobileDatePicker
                            inputFormat="YYYY年 MM月 DD日"
                            value={date}
                            readOnly={isViewMode}
                            disabled={false}
                            label={'勤務日'}
                            onChange={dateHandleChange}
                            renderInput={(params: any) => {
                                params.inputProps.readOnly = true
                                params.inputProps.placeholder = ''
                                return <TextField {...params} InputProps={{
                                    endAdornment:
                                        <Calendar sx={{
                                            fontSize: '20px',
                                            cursor: 'pointer'
                                        }} fill={'cool-gray-400'}/>,
                                }}/>
                            }}
                        />
                        <MobileTimePicker
                            readOnly={isViewMode}
                            label="作業内容"
                            inputFormat={'hh:mm A'}
                            value={time}
                            onChange={timeHandleChange}
                            renderInput={(params: any) => {
                                params.inputProps.readOnly = true
                                params.inputProps.placeholder = ''
                                return <TextField {...params} InputProps={{
                                    endAdornment:
                                        <Clock sx={{
                                            fontSize: '20px',
                                            cursor: 'pointer'
                                        }} fill={'cool-gray-400'}/>
                                }}/>
                            }}
                        />
                    </Stack>

                    <TextField
                        inputProps={{
                            readOnly: isViewMode
                        }}
                        variant={'outlined'}
                        label={
                            <Typography
                                variant={'sm'}
                                fontWeight={500}
                                color={colors["cool-gray-600"]}>
                                作業実施工数&nbsp;
                                <Typography
                                    variant={'sm'}
                                    fontWeight={400}
                                    color={colors["cool-gray-500"]}>
                                    (e.g. 1d 4h 3m)
                                </Typography>
                            </Typography>
                        }
                        {...register('manHour')}
                        error={!!errors.manHour}
                        fullWidth/>
                    <TextField
                        inputProps={{
                            readOnly: isViewMode
                        }}
                        variant={'outlined'}
                        label={'工程進捗率'}
                        {...register('rate')}
                        error={!!errors.rate}
                        fullWidth
                        InputProps={{
                            endAdornment:
                                <Typography
                                    variant={'sm'}
                                    fontWeight={700}
                                    color={colors["cool-gray-400"]}>
                                    %
                                </Typography>
                        }}/>
                </Stack>

                { !isViewMode && <Stack
                    direction={'column'}
                    gap={'16px'}
                    padding={'16px'}
                    sx={{background: colors["cool-gray-50"]}}>
                    <Button variant={'contained'}
                            onClick={handleSubmit(onFormSubmit)}>
                        登録
                    </Button>
                    <Button variant="outlined" size={"small"}
                            onClick={tempSave}   color={'primary'}>
                        キャンセル
                        {/*一時保存*/}
                    </Button>
                    {/*<Button variant="outlined" size={"small"} color={'error'}>*/}
                    {/*    破棄*/}
                    {/*</Button>*/}
                </Stack> }
            </StyledDialogContent>

        </Dialog>
    )
}

export const StyledDialogContent = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    padding: 0
})

export default ReportDialog