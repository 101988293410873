import {createTheme} from "@mui/material";
import React from "react";
import themeOptions from "./theme.options";

declare module '@mui/material/styles' {
    interface Theme {
    }

    interface Palette {
    }
    interface PaletteOptions {
    }

    interface TypographyOptions {
        xs: React.CSSProperties
        sm: React.CSSProperties
        base: React.CSSProperties
        lg: React.CSSProperties
        xl: React.CSSProperties
        '2xl': React.CSSProperties
        '3xl': React.CSSProperties
        '4xl': React.CSSProperties
        '5xl': React.CSSProperties
        '6xl': React.CSSProperties
    }

    interface TypographyVariants {
        xs: React.CSSProperties
        sm: React.CSSProperties
        base: React.CSSProperties
        lg: React.CSSProperties
        xl: React.CSSProperties
        '2xl': React.CSSProperties
        '3xl': React.CSSProperties
        '4xl': React.CSSProperties
        '5xl': React.CSSProperties
        '6xl': React.CSSProperties
    }

    interface TypographyVariantsOptions {
        xs?: React.CSSProperties
        sm?: React.CSSProperties
        base?: React.CSSProperties
        lg?: React.CSSProperties
        xl?: React.CSSProperties
        '2xl'?: React.CSSProperties
        '3xl'?: React.CSSProperties
        '4xl'?: React.CSSProperties
        '5xl'?: React.CSSProperties
        '6xl'?: React.CSSProperties
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        xs: true
        sm: true
        base: true
        lg: true
        xl: true
        '2xl': true
        '3xl': true
        '4xl': true
        '5xl': true
        '6xl': true
    }
}

const theme = createTheme(themeOptions)

export default theme