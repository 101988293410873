import {SvgIcon, SvgIconProps, SxProps} from "@mui/material";
import React from "react";
import colors from "../../styles/colors";

type DismissProps = {
    sx?: SxProps,
    fill?: keyof typeof colors
} & SvgIconProps
const Dismiss: React.FC<DismissProps> = ({ fill = 'cool-gray-900', sx, ...props }) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 28 28'} sx={sx} >
            <path fill={colors[fill]} d="M4.08489 4.10051C4.47541 3.70999 5.10857 3.70999 5.4991 4.10051L14.0001 12.6015L22.5012 4.10051C22.8917 3.70999 23.5249 3.70999 23.9154 4.10051C24.3059 4.49103 24.3059 5.1242 23.9154 5.51472L15.4143 14.0158L23.883 22.4845C24.2736 22.875 24.2736 23.5081 23.883 23.8987C23.4925 24.2892 22.8594 24.2892 22.4688 23.8987L14.0001 15.43L5.53144 23.8987C5.14091 24.2892 4.50775 24.2892 4.11722 23.8987C3.7267 23.5081 3.7267 22.875 4.11722 22.4845L12.5859 14.0158L4.08489 5.51472C3.69436 5.1242 3.69436 4.49103 4.08489 4.10051Z" />
        </SvgIcon>
    )
}

export default Dismiss