import React from "react";
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";
import MiniCalendar from "../molecules/MiniCalendar";
import colors from "../../styles/colors";

export type CalendarHeaderProps = {
    onNext: () => void,
    onPrevious: () => void,
    date: string,
    currentDay: string,
    loading: boolean
}

const CalendarHeader: React.FC<CalendarHeaderProps> = (props) => {

    const {onNext, onPrevious, date, currentDay, loading} = props

    return (
        <Stack
            height={'38px'}
            direction={'row'}
            display={'flex'}
            gap={'16px'}
            alignItems={'center'}
            padding={'9px 16px'}>

            <Typography
                variant={'sm'}
                fontWeight={700}
                color={colors["cool-gray-900"]}>
                {date}
            </Typography>
            {
                loading && <CircularProgress size={16} sx={{position: 'relative'}}/>
            }

            <Box flexGrow={1}/>
            <ArrowLeft
                sx={{
                    fontSize: '20px',
                    cursor: 'pointer',
                    marginRight : '8px'
                }}
                fill={loading ? 'gray-50' : 'cool-gray-600'} onClick={onPrevious}/>
            <ArrowRight
                sx={{
                    fontSize: '20px',
                    cursor: 'pointer',
                    marginRight: '8px'
                }}
                fill={loading ? 'gray-50' : 'cool-gray-600'} onClick={onNext}/>
            <MiniCalendar date={currentDay} />
        </Stack>
    )
}

export default CalendarHeader