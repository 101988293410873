import {inputLabelClasses, ThemeOptions} from "@mui/material";
import colors, {primary} from "./styles/colors";
import fonts from "./styles/fonts";

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: colors[primary]
        },
        error: {
            main: colors["red-500"]
        }
    },
    typography: {
        fontFamily: [
            'Noto Sans JP',
            'sans-serif'
        ].join(','),
        xs: { fontSize: '.75rem', lineHeight: '1rem' },
        sm: { fontSize: '.857rem', lineHeight: '1.25rem' },
        base: { fontSize: '1rem', lineHeight: '1.5rem' },
        lg: { fontSize: '1.125rem', lineHeight: '1.75rem' },
        xl: { fontSize: '1.25rem', lineHeight: '1.75rem' },

        '2xl': { fontSize: '1.5rem', lineHeight: '2rem' },
        '3xl': { fontSize: '1.857rem', lineHeight: '2.25rem' },
        '4xl': { fontSize: '2.25rem', lineHeight: '2.5rem' },
        '5xl': { fontSize: '3rem', lineHeight: '3rem' },
        '6xl': { fontSize: '3.75rem', lineHeight: '3.75rem' }
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                icon: {
                    fontSize: '20px',
                    '& path': {
                        fill: colors["cool-gray-400"]
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    borderRadius: '4px 4px 0px 0px',
                    height: '4px'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 'unset',
                    padding: '12px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    height: '38px',
                    position: 'unset',
                    textTransform: 'none',
                    ...fonts.sm,
                    fontWeight: 500
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    [`& .${inputLabelClasses.root}`]: {
                        position: 'unset',
                        transform: 'none',
                        marginBottom: '4px',
                        ...fonts.sm,
                        fontWeight: 500,
                        color: colors["cool-gray-600"]
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '6px'
                },
                notchedOutline: {
                    top: 0,
                    '& legend': {
                        display: 'none'
                    }
                },
                input: {
                    ...fonts.sm,
                    fontWeight: 400,
                    color: colors["cool-gray-900"],
                    padding: '9px 13px'
                }
            }
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true
            },
            styleOverrides: {
                root: {
                    position: 'unset',
                    transform: 'none',
                    marginBottom: '4px',
                    ...fonts.sm,
                    fontWeight: 500,
                    color: colors["cool-gray-600"]
                }
            }
        }
    }
}

export default themeOptions