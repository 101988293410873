import { Dialog, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch, RootState } from "../../store"
import Dismiss from "../icons/Dismiss"
import colors from "../../styles/colors";
import TaskItem from "./TaskItem";
import dayjs from "dayjs";


const TaskListDialog = () => {

    const currentTab = useSelector((root: RootState) => root.home!.currentTab)
    const tasksToOpen = useSelector((root: RootState) =>  root.home.tasksToOpen)
    const dispatch = useDispatch<Dispatch>()
    const open = useSelector((root: RootState) => root.home?.openTaskListDialog)

    const handleClose = () => {
        dispatch.home.closeTaskListDialog()
    }

    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>
            <Stack direction={'column'} gap={1}>
                <Stack direction={'row'}
                    justifyContent={'space-between'}
                    padding={'16px'}
                    height={'56px'}
                    sx={{ background: 'white' }}>
                    <Typography
                        variant={'base'}
                        color={colors["cool-gray-900"]}
                        fontWeight={700}>
                        {dayjs(tasksToOpen.date).format('DD日 MM月 YYYY年')}
                    </Typography>
                    <Dismiss sx={{
                        fontSize: '24px',
                        cursor: 'pointer'
                    }} fill={'cool-gray-400'} onClick={handleClose} />
                </Stack>
                {
                    tasksToOpen.tasks?.map((task: any, index: number) => (
                        <TaskItem
                            key={index}
                            onView={() => {
                                dispatch.tasks.setCurrentTask(task)
                                dispatch.home.openReportDialog(currentTab === 0)
                            }}
                            onEdit={() => {
                                dispatch.tasks.setCurrentTask(task)
                                dispatch.home.openReportDialog()
                            }}
                            id={-1}
                            taskName={task.matternumber}
                            status={
                                task.achievementrate === 100 ? 'finished' :
                                    task.achievementrate === 0 ? 'not-reported' : 'draft'
                            }
                            reportedDate={dayjs(task.workdate).format('DD日 MM月 YYYY年')}
                            reportedTime={task.worktime}
                            progress={task.achievementrate}
                        />
                    ))
                }
            </Stack>
        </Dialog>
    )

}

export default TaskListDialog