import { createModel } from "@rematch/core";
import { RootModel } from "./index";

export type AuthStateType = {
    isAuthenticated: boolean
    user: {
        code: number,
        flag: number,
        name: string,
        admin: number
    } | null
}

const authInitialState: AuthStateType = {
    isAuthenticated: false,
    user: null
}

export const auth = createModel<RootModel>()({
    name: 'auth',
    state: authInitialState,
    reducers: {
        saveLoginInfo(state, data) {
            state.isAuthenticated = true
            state.user = data
        },

        logout(state) {
            state.isAuthenticated = false
            state.user = null
        }
    },
    effects: (_) => ({

    })
})