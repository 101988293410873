import {SvgIcon, SvgIconProps, SxProps} from "@mui/material";
import React from "react";
import colors from "../../styles/colors";

type ArrowLeftProps = {
    sx?: SxProps,
    fill?: keyof typeof colors
} & SvgIconProps
const ArrowLeft: React.FC<ArrowLeftProps> = ({ fill = 'cool-gray-900', sx, ...props }) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 20 20'} sx={sx} >
            <path fill={colors[fill]} d="M8.73171 16.7951C9.03264 17.0797 9.50733 17.0665 9.79196 16.7656C10.0766 16.4646 10.0634 15.9899 9.76243 15.7053L4.52339 10.7502H17.2471C17.6613 10.7502 17.9971 10.4144 17.9971 10.0002C17.9971 9.58598 17.6613 9.25019 17.2471 9.25019H4.52112L9.76243 4.29294C10.0634 4.00831 10.0766 3.53362 9.79196 3.23269C9.50733 2.93175 9.03264 2.91853 8.73171 3.20316L2.31449 9.2726C2.14819 9.42989 2.04819 9.63 2.01448 9.83879C2.00308 9.89077 1.99707 9.94478 1.99707 10.0002C1.99707 10.0578 2.00356 10.1139 2.01585 10.1677C2.05084 10.3735 2.15039 10.5704 2.31449 10.7256L8.73171 16.7951Z" />
        </SvgIcon>
    )
}

export default ArrowLeft