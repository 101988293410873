import { Box, styled, Typography } from "@mui/material";
import colors from './../../styles/colors';
import { useDispatch } from "react-redux";
import { Dispatch } from "../../store";
import { DayAttr, TaskAttr } from "../../utils/dayUtils";

type DayCellProp = {
    data: DayAttr
}

export const DayCell = (props: DayCellProp) => {

    const {
        day,
        isToday,
        date,
        dayInThisMonth,
    } = props.data

    const dispatch = useDispatch<Dispatch>()

    const onSelectTask = () => {
        if (props.data.tasks.length > 0) {
            dispatch.home.setTasksToOpen({
                tasks: props.data.tasks.map(t => t.taskResponse),
                date: date
            })
            dispatch.home.openTaskListDialog()
        }
    }

    return (
        <Content bgcolor={isToday ? colors['blue-50'] : 'white'} onClick={onSelectTask}>
            {
                isToday ? <TodayTypo>{day}</TodayTypo> :
                    dayInThisMonth ? <DayInThisMonthTypo>{day} </DayInThisMonthTypo> :
                        <DayFromOtherMonthTypo>{day}</DayFromOtherMonthTypo>
            }
            <TaskContainer>
                {
                    props.data.tasks?.map((task: TaskAttr, index: number) => (
                        <TaskTypo key={index}>{task.name}</TaskTypo>
                    ))
                }
            </TaskContainer>
        </Content>
    )
}

const Content = styled(Box)({
    display: 'grid',
    height: '74px',
    boxShadow: '0px -0.5px 0px 0px #E5E7EB',
    justifyItems: 'center',
    alignItems: 'start'
})

const DayFromOtherMonthTypo = styled(Typography)({
    color: colors['cool-gray-400'],
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '4px'
})

const DayInThisMonthTypo = styled(Typography)({
    color: colors['cool-gray-900'],
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '4px'
})

const TodayTypo = styled(Typography)({
    color: 'white',
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '2px',
    backgroundColor: colors['blue-600'],
    width: '20px',
    height: '20px',
    textAlign: 'center',
    borderRadius: '50%'
})

const TaskContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '2px'
})

const TaskTypo = styled(Typography)({
    color: colors['cool-gray-600'],
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '1px',
    padding: '1px',
    backgroundColor: colors['gray-200'],
    borderRadius: '2px'
})