import {Box, BoxProps, styled, Typography} from "@mui/material";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import colors from "../../styles/colors";
import {blue} from "@mui/material/colors";
import {DayAttr, mergeWithTasks, UNIT_DAY} from "../../utils/dayUtils";
import {
    getDaysFor3DaysView,
    getDaysForOneDayView,
    getDaysHeaderForOneDayView,
    getStartDateAndEndDateForSelectedDay
} from "../../utils/threeDaysViewUtils";
import {getDayReport} from "../../services/apis/api.service";
import CalendarHeader from "./CalendarHeader";
import Switch2 from "./Switch2";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../store";
import useMatters from "../../hooks/useMatters";

const TIME_FORMAT = 'HH:00'

export const WeekView = () => {
    const dispatch = useDispatch<Dispatch>()
    const code = useSelector((root: RootState) => root.auth.user?.code || 0)
    const [currentDay, setCurrentDay] = useState(dayjs())
    const [content, setContent] = useState<DayAttr[]>(getDaysForOneDayView(currentDay))
    const [headDays, setHeadDays] = useState<{ dayIndex: string, isToday: boolean, selectedDay: boolean }[]>(getDaysHeaderForOneDayView(currentDay))
    const [loading, setLoading] = useState(false)
    const [direction, setDirection] = useState<'none' | 'down' | 'up'>('none')
    const currentTab = useSelector((root: RootState) => root.home.currentTab || 0)

    const {fetch} = useMatters((data) => {
        mergeTasks(data)
    })

    useEffect(() => {
        setContent(getDaysFor3DaysView(currentDay))
        setHeadDays(getDaysHeaderForOneDayView(currentDay))

        if (currentTab !== 0) {
            fetch()
        }
        if (currentTab === 0) fetchDayTasks()
        // @ts-ignore
        window.reloadData = currentTab === 0 ? fetchDayTasks : fetch
    }, [currentDay])

    const fetchDayTasks = () => {
        setLoading(true)
        let value = getStartDateAndEndDateForSelectedDay(currentDay)
        getDayReport({code: code, startDate: value.startDate, endDate: value.endDate})
            .then(response => {
                mergeTasks(response.data)
            }).catch(_ => {
            setContent(getDaysFor3DaysView(currentDay))
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const mergeTasks = (response: any) => {
        setContent(mergeWithTasks(getDaysForOneDayView(currentDay), response))
    }

    const toNextThreeDays = () => {
        if (loading) return
        const nextDay = dayjs(currentDay).add(1, UNIT_DAY)
        setCurrentDay(nextDay)
    }

    const toPreviousThreeDays = () => {
        if (loading) return
        const previousDay = dayjs(currentDay).subtract(1, UNIT_DAY)
        setCurrentDay(previousDay)
    }

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        if (event.currentTarget.scrollTop > 100)
            setDirection('down')
        else setDirection('none')
    }

    return (
        <Content>
            <CalendarHeader onNext={toNextThreeDays}
                            onPrevious={toPreviousThreeDays}
                            currentDay={dayjs().format('DD')}
                            date={currentDay.format('YYYY年 MM月')}
                            loading={loading}/>
            <HeadRow>
                <Box boxShadow={'0.5px 0px 0px 0px #E5E7EB'}/>
                {
                    headDays.map((item, index: number) => (
                        <HeadCell key={index}>
                            {
                                item.selectedDay ? <HeadTypo>{item.dayIndex}</HeadTypo> :
                                    item.isToday ? <SelectedDayTypo>{item.dayIndex}</SelectedDayTypo> :
                                        <Typo>{item.dayIndex}</Typo>
                            }
                        </HeadCell>
                    ))
                }
            </HeadRow>
            <TaskContainer onScroll={handleScroll}>
                <TimeColumn>
                    {
                        (new Array(24)).fill(1).map((_, index) => {
                            return (
                                <TimeCell key={index}>
                                    <Typography variant={'xs'} color={colors["cool-gray-500"]} lineHeight={'0.5rem'}>
                                        {dayjs().set('hour', (index + 1)).format(TIME_FORMAT)}
                                    </Typography>
                                </TimeCell>
                            )
                        })
                    }
                </TimeColumn>

                <TaskColumn>
                    {
                        content[0].tasks.map((task, index) =>
                            <TaskViewContainer
                                onClick={() => {
                                    dispatch.tasks.setCurrentTask(task.taskResponse)
                                    dispatch.home.openReportDialog()
                                }}
                                gridRow={`${task.startSpan} / span ${task.endSpan}`} key={index}>
                                <Box bgcolor={blue[200]} borderRadius={'4px'}>
                                    <TaskTypo>{task.name}</TaskTypo>
                                </Box>
                            </TaskViewContainer>
                        )
                    }
                </TaskColumn>

            </TaskContainer>
            <Switch2 direction={direction}/>
        </Content>
    )
}


const HEAD_BOX_WIDTH = '56px'
const HEAD_BOX_HEIGHT = '32px'
const BODY_BOX_HEIGHT = '25px'
const TIME_BOX_HEIGHT = '50px'

const Content = styled(Box)({
    display: 'grid',
    gridTemplateRows: 'repeat(2,min-content) 1fr',
    overflowY: 'scroll',
    height: 'calc(100vh - 126px)'
})

const HeadRow = styled(Box)({
    display: 'grid',
    gridTemplateColumns: `${HEAD_BOX_WIDTH} repeat(7,1fr)`,
    height: `${HEAD_BOX_HEIGHT}`,
    borderTop: '1px solid #E5E7EB',
    boxShadow: '0px 0.5px 0px 0px #E5E7EB'
})

const HeadCell = styled(Box)({
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center'
})

const Typo = styled(Typography)({
    color: colors['cool-gray-900'],
    fontSize: '12px',
    fontWeight: 400
})

const HeadTypo = styled(Typography)({
    color: 'white',
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '2px',
    backgroundColor: colors['blue-600'],
    width: '20px',
    height: '20px',
    textAlign: 'center',
    borderRadius: '50%'
})

const SelectedDayTypo = styled(Typo)({
    color: colors['blue-600'],
    backgroundColor: colors['blue-50'],
    width: '20px',
    height: '20px',
    textAlign: 'center',
    borderRadius: '50%'
})

const TaskContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: `${HEAD_BOX_WIDTH} 1fr`,
    overflow: 'scroll',
    paddingBottom: '64px'
})

const TimeColumn = styled(Box)({
    display: 'grid',
    gridTemplateRows: `repeat(24,${TIME_BOX_HEIGHT})`,
    justifyContent: 'center',
    alignContent: 'center',
    boxShadow: '0.5px 0px 0px 0px #E5E7EB',
})


const TimeCell = styled(Box)({
    display: 'grid',
    alignItems: 'end'
})

const TaskColumn = styled(Box)({
    display: 'grid',
    gridTemplateRows: `repeat(24,${BODY_BOX_HEIGHT})`,
})

const TaskViewContainer = styled(Box)<BoxProps>({
    display: 'grid',
    boxShadow: '0px 0.5px 0px 0px #E5E7EB',
    padding: '2px'
})

const TaskTypo = styled(Box)({
    color: colors['blue-600'],
    fontSize: '12px',
    fontWeight: 400,
    marginTop: 'auto',
    marginBottom: 'auto'
})