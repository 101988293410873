import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import tokenService from "../token.service";

const client: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export function getHttpClient() {
    return async (config: AxiosRequestConfig) => {
        let res = null;
        let err = null;
        try {
            if(tokenService.getToken() == "")
                res = await client({
                    ...config,
                    headers: {
                        'Access-Control-Request-Private-Network': true
                    }
                })
            else {
                res = await client({
                    ...config,
                    headers: {
                        Authorization: `Bearer ${tokenService.getToken()}`,
                        'Access-Control-Request-Private-Network': true
                    }
                })
            }
        } catch (e) {
            err = e;
        }
        return [res, err] as [AxiosResponse, Error | AxiosError];
    }
}