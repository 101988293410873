import { Models } from "@rematch/core";
import {auth} from "./auth";
import { login } from "./login";
import {home} from "./home";
import {tasks} from "./tasks";
import {search} from "./search";
import {taskChooser} from "./taskChooser";

export interface RootModel extends Models<RootModel> {
    auth: typeof auth,
    login: typeof login,
    home: typeof home,
    tasks: typeof tasks,
    search: typeof search
    taskChooser: typeof taskChooser
}

export const models: RootModel = {
    auth,
    login,
    home,
    tasks,
    search,
    taskChooser
};