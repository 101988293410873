const elevations = {
    sm: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    base: '1px 2px 4px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.025)',
    md: '0px 4px 6px -1px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(0, 0, 0, 0.03)',
    lg: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0px 2px 4px rgba(0, 0, 0, 0.06);inset 0px 2px 4px rgba(0, 0, 0, 0.06)',

    y: '0px 1px 0px #E4E4E7',
    x: '1px 0px 0px #E4E4E7',
    '-y': '0px -1px 0px #E4E4E7',
    '-x': '-1px 0px 0px #E4E4E7'
}

export default elevations