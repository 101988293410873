const colors = {
    white: '#FFFFFF',

    'amber-100': '#fef3c7',
    'amber-200': '#FDE68A',
    'amber-400': '#FBBF24',
    'amber-500': '#F59E0B',
    'amber-600': '#D97706',
    'amber-700': '#B45309',

    'gray-50': '#F9FAFB',
    'gray-200': '#E4E4E7',
    'gray-300': '#D1D5DB',
    'gray-500': '#6B7280',
    'gray-900': '#111827',

    'teal-100': '#CCFBF1',
    'teal-200': '#99f6e4',
    'teal-400': '#2DD4BF',
    'teal-600': '#0D9488',
    'teal-700': '#0F766E',

    'red-100': '#FEE2E2',
    'red-500': '#EF4444',
    'red-600': '#DC2626',

    'emerald-100': '#d1fae5',
    'emerald-200': '#A7F3D0',
    'emerald-500': '#10B981',
    'emerald-600': '#059669',

    'pink-100': '#FCE7F3',
    'pink-200': '#FBCFE8',
    'pink-400': '#F472B6',
    'pink-500': '#EC4899',
    'pink-600': '#DB2777',

    'blue-50' : '#EFF6FF',
    'blue-100': '#DBEAFE',
    'blue-600': '#2563EB',

    'indigo-100': '#e0e7ff',
    'indigo-200': '#C7D2FE',
    'indigo-400': '#818CF8',
    'indigo-500': '#6366F1',
    'indigo-600': '#4F46E5',

    'light-blue-200': '#BAE6FD',
    'light-blue-400': '#38BDF8',
    'light-blue-600': '#0284C7',
    'light-blue-700': '#0369A1',

    'lime-100': '#ECFCCB',
    'lime-200': '#D9F99D',
    'lime-600': '#65A30D',
    'lime-700': '#4D7C0F',

    'cool-gray-50': '#F9FAFB',
    'cool-gray-100': '#F3F4F6',
    'cool-gray-200': '#E5E7EB',
    'cool-gray-300': '#D1D5DB',
    'cool-gray-400': '#9CA3AF',
    'cool-gray-500': '#6B7280',
    'cool-gray-600': '#4B5563',
    'cool-gray-800': '#1F2937',
    'cool-gray-900': '#111827',

    'blue-gray-400': '#94A3B8',
    'blue-gray-600': '#475569',
    'blue-gray-900': '#0F172A',

    'green-100': '#DCFCE7',
    'green-200': '#BBF7D0',
    'green-300': '#86EFAC',
    'green-400': '#4ADE80',
    'green-600': '#16A34A',
    'green-700': '#15803D',

    'violet-200' : '#DDD6FE',
    'violet-500' : '#8B5CF6',
    'violet-600': '#7C3AED',
    'violet-700' : '#6D28D9',

    'orange-200' : '#FED7AA',
    'orange-500' : '#F97316',
    'orange-700' : '#C2410C',

    'cyan-100': '#cffafe',
    'cyan-200': '#a5f3fc',
    'cyan-500': '#06B6D4',
    'cyan-600' : '#0891B2',

    'yellow-200': '#FEF08A',
    'yellow-400': '#FACC15',
    'yellow-600': '#CA8A04',
    'yellow-700': '#A16207',

    'fuschia-200': '#F5D0FE',
    'fuschia-700': '#A21CAF'
}

export const primary = 'blue-600'

export default colors