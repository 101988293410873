import dayjs, {Dayjs} from "dayjs";
import utc from "dayjs/plugin/utc";
import isToday from 'dayjs/plugin/isToday';
import {DayAttr, groupTaskWithDate, TaskAttr, TEMPLATE_D, TIME_FORMAT, UNIT_DATE, UNIT_MONTH} from "./dayUtils";

dayjs.extend(isToday)
dayjs.extend(utc);


export function getDaysForCalendar(date: Dayjs): DayAttr[] {
    // const currentDate = dayjs()
    const currentDate = date
    const daysInMonth = dayjs(currentDate).daysInMonth();

    const days = new Array(daysInMonth).fill(1).map((_, index) => {
            const date = dayjs(currentDate).set(UNIT_DATE, index + 1)
            return {
                dayIndex: date.day(),
                day: date.format(TEMPLATE_D),
                isToday: date.isToday(),
                date: date.format(TIME_FORMAT),
                dayInThisMonth: true,
                tasks: <TaskAttr[]>[]
            }
        }
    )

    const previousMonth = dayjs(currentDate).subtract(1, UNIT_MONTH)
    const noOfDaysInPreviousMonth = previousMonth.daysInMonth();

    const noOfPreviousRemainingDay = days[0].dayIndex
    const previousDays = new Array(noOfPreviousRemainingDay).fill(1)
        .map((_, index) => {
            const date = dayjs(previousMonth).set(UNIT_DATE, (noOfDaysInPreviousMonth - noOfPreviousRemainingDay) + (index + 1))
            return {
                dayIndex: date.day(),
                day: date.format(TEMPLATE_D),
                isToday: false,
                date: date.format(TIME_FORMAT),
                dayInThisMonth: false,
                tasks: <TaskAttr[]>[]
            }
        })

    const nextMonth = dayjs(currentDate).add(1, UNIT_MONTH)
    const noOfNextRemainingDay = 6 - days[days.length - 1].dayIndex
    const nextDays = new Array(noOfNextRemainingDay).fill(1)
        .map((_, index) => {
            const date = dayjs(nextMonth).set(UNIT_DATE, index + 1)
            return {
                dayIndex: date.day(),
                day: date.format(TEMPLATE_D),
                isToday: false,
                date: date.format(TIME_FORMAT),
                dayInThisMonth: false,
                tasks: <TaskAttr[]>[]
            }
        })

    return previousDays.concat(days).concat(nextDays)

}



export function getStartDateAndEndDateForSelectedMonth(currentDate: Dayjs): { startDate: string, endDate: string } {
    let previousMonth = dayjs(currentDate).subtract(1, UNIT_MONTH)
    const nextMonth = dayjs(currentDate).add(1, UNIT_MONTH)
    let startDate = previousMonth.set(UNIT_DATE, 1).format(TIME_FORMAT)
    let endDate = nextMonth.set(UNIT_DATE, nextMonth.daysInMonth()).format(TIME_FORMAT)
    return {startDate, endDate}
}


