import {SvgIcon, SvgIconProps, SxProps} from "@mui/material";
import React from "react";
import colors from "../../styles/colors";

type SignOutProps = {
    sx?: SxProps,
    fill?: keyof typeof colors
} & SvgIconProps
const SignOut: React.FC<SignOutProps> = ({ fill = 'cool-gray-900', sx, ...props }) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 24 24'} sx={sx} >
            <path fill={colors[fill]} d="M6.50215 8.49953C7.05562 8.49953 7.5043 8.94821 7.5043 9.50168C7.5043 10.0552 7.05562 10.5038 6.50215 10.5038C5.94868 10.5038 5.5 10.0552 5.5 9.50168C5.5 8.94821 5.94868 8.49953 6.50215 8.49953ZM10 1.3537V7.49951L10.0005 8.00448L17.442 8.00352L15.7196 6.27977C15.4534 6.01346 15.4292 5.59679 15.6471 5.30321L15.7198 5.21911C15.9861 4.95289 16.4027 4.92875 16.6963 5.14666L16.7804 5.21929L19.777 8.21694C20.043 8.483 20.0674 8.8992 19.85 9.19278L19.7775 9.2769L16.7809 12.2803C16.4884 12.5736 16.0135 12.5741 15.7203 12.2816C15.4537 12.0156 15.429 11.599 15.6465 11.3051L15.7191 11.2209L17.432 9.50352L10.0005 9.50448L10 16.2495C10 16.7159 9.57884 17.0692 9.11955 16.9881L0.619552 15.4868C0.26121 15.4235 0 15.1121 0 14.7482V2.74953C0 2.38222 0.266014 2.06896 0.628466 2.00944L9.12847 0.613609C9.58508 0.538626 10 0.890966 10 1.3537ZM8.5 2.2369L1.5 3.38641V14.1191L8.5 15.3555V2.2369ZM11 15.5008L11.7652 15.501L11.867 15.4941C12.2335 15.4443 12.5158 15.1299 12.5152 14.7497L12.508 10.4995H11V15.5008ZM11.002 6.99953L11 5.72487V1.99952L11.7453 1.99953C12.1245 1.99953 12.4381 2.28105 12.4883 2.64664L12.4953 2.74829L12.502 6.99953H11.002Z" />
        </SvgIcon>
    )
}

export default SignOut