import {SvgIcon, SvgIconProps, SxProps} from "@mui/material";
import React from "react";
import colors from "../../styles/colors";

type ArrowRightProps = {
    sx?: SxProps,
    fill?: keyof typeof colors
} & SvgIconProps
const ArrowRight: React.FC<ArrowRightProps> = ({ fill = 'cool-gray-900', sx, ...props }) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 20 20'} sx={sx} >
            <path fill={colors[fill]} d="M11.2654 3.20511C10.9644 2.92049 10.4897 2.93371 10.2051 3.23464C9.92049 3.53558 9.93371 4.01027 10.2346 4.29489L15.4737 9.25H2.75C2.33579 9.25 2 9.58579 2 10C2 10.4142 2.33579 10.75 2.75 10.75H15.476L10.2346 15.7073C9.93371 15.9919 9.92049 16.4666 10.2051 16.7675C10.4897 17.0684 10.9644 17.0817 11.2654 16.797L17.6826 10.7276C17.8489 10.5703 17.9489 10.3702 17.9826 10.1614C17.994 10.1094 18 10.0554 18 10C18 9.94241 17.9935 9.88633 17.9812 9.83246C17.9462 9.62667 17.8467 9.42976 17.6826 9.27455L11.2654 3.20511Z" />
        </SvgIcon>
    )
}

export default ArrowRight