import {createModel} from "@rematch/core";
import {RootModel} from "./index";

export enum ViewType {
    MONTH , THREEDAYS , DAY , LIST
}

type TasksToOpen = {
    tasks: any[]
    date  : string 
}

export type HomeStateType = {
    currentTab: number,
    viewType: ViewType,
    taskType: string,
    openReportDialog: boolean,
    openTaskListDialog : boolean,
    tasksToOpen : TasksToOpen,
    viewMode: boolean
}

const initialState: HomeStateType = {
    currentTab: 0,
    viewType: ViewType.THREEDAYS,
    taskType: 'one',
    viewMode: false,
    openReportDialog: false,
    openTaskListDialog : false ,
    tasksToOpen : { tasks : [] , date : ""}
}

export const home = createModel<RootModel>() ({
    name: 'home',
    state: initialState,
    reducers: {
        setTab(state, tab: number) {
            state.currentTab = tab
        },
        setViewTypeState(state, viewType: typeof initialState.viewType) {
            state.viewType = viewType
        },
        setTaskType(state, taskType: string) {
            state.taskType = taskType
        },
        openReportDialog(state, viewMode = false) {
            state.openReportDialog = true
            state.viewMode = viewMode
        },
        _closeReportDialog(state) {
            state.openReportDialog = false
        },
        openTaskListDialog(state){
            state.openTaskListDialog = true
        },
        closeTaskListDialog(state){
            state.openTaskListDialog = false 
        },
        setTasksToOpen(state, payload: typeof initialState.tasksToOpen) {
            state.tasksToOpen = payload
        }
    },

    effects: dispatch => {
        const effects: any = {}

        effects.setViewType = (viewType: typeof initialState.viewType, _ : any) => {
            dispatch.home.setViewTypeState(viewType)
            dispatch.tasks.setCurrentTask(undefined)
        }

        effects.closeReportDialog = (_: any, __: any) => {
            dispatch.home._closeReportDialog()
            dispatch.tasks.loadTaskList()
            dispatch.taskChooser.close()
            dispatch.home.closeTaskListDialog()
        }

        return effects
    }
})