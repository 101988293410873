import { GetWorkParam, ReportParam } from "../types";
import { getHttpClient } from "./httpClient";

const client = getHttpClient()

export async function doLogin(userId: string, password: string) {
    const [res, error]: any = await client({
        url: '/get_user',
        method: 'GET',
        params: {
            user: userId,
            password: password
        }
    })
    if (error) return null
    return res.data
}

export async function getDayReportsList() {
    const [res, error]: any = await client({
        url: '/dayreports',
        method: 'GET'
    })
    if (error) return []
    return res.data
}

export async function getDayReport(param : any) {
    const [res, error]: any = await client({
        url: `/get_dayreport`,
        method: 'GET',
        params: {
            code  : param.code,
            startdate: param.startDate,
            enddate: param.endDate,
        }
    })
    if (error) throw new Error(error)
    return res
}

export async function putDayReport(param : ReportParam) {
    const [res , error ] : any = await client({
        url : '/put_dayreport',
        method : 'POST',
        data : param
    })
    if(error) throw new Error(error)
    return res
}

export async function getMatter(code : String){
    const [res , error ] : any = await client({
        url : '/get_matter',
        method : 'GET',
        params: { code }
    })
    if (error) throw new Error(error)
    return res
}

export async function getWork(param : GetWorkParam){
    const [res , error ] : any = await client({
        url : '/get_work',
        method : 'GET',
        params : param 
    })
    if (error) throw new Error(error)
    return res
}