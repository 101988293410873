import {SvgIcon, SvgIconProps, SxProps} from "@mui/material";
import React from "react";
import colors from "../../styles/colors";

type SearchProps = {
    sx?: SxProps,
    fill?: keyof typeof colors
} & SvgIconProps
const Search: React.FC<SearchProps> = ({ fill = 'cool-gray-900', sx, ...props }) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 48 48'} sx={sx} >
            <path fill={colors[fill]} d="M14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28C17.4159 28 20.5461 26.7766 22.9763 24.7441L33.8662 35.6339C34.3543 36.122 35.1458 36.122 35.6339 35.6339C36.1221 35.1457 36.1221 34.3543 35.6339 33.8661L24.7441 22.9763C26.7766 20.5461 28 17.4159 28 14C28 6.26801 21.732 0 14 0ZM2.5 14C2.5 7.64873 7.64873 2.5 14 2.5C20.3513 2.5 25.5 7.64873 25.5 14C25.5 20.3513 20.3513 25.5 14 25.5C7.64873 25.5 2.5 20.3513 2.5 14Z" />
        </SvgIcon>
    )
}

export default Search



