import React, {useEffect, useState} from "react";
import {Box, Stack, styled, Typography} from "@mui/material";
import TaskItem from "../../../components/organisms/TaskItem";
import {Dispatch, RootState} from "../../../store"
import {useDispatch, useSelector} from "react-redux";
import Switch2 from "../../../components/organisms/Switch2";
import dayjs from "dayjs";
import colors from "../../../styles/colors";
import useMatters from "../../../hooks/useMatters";

const ReportList: React.FC<any> = () => {
    const tasks = useSelector((root: RootState) => root.tasks)
    const currentTab = useSelector((root: RootState) => root.home!.currentTab)

    const { matters } = useMatters()

    const dispatch = useDispatch<Dispatch>()
    const [direction, setDirection] = useState<'none' | 'down' | 'up'>('none')

    useEffect(() => {
       dispatch.tasks.loadTaskList()
    }, [])

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        if (event.currentTarget.scrollTop > 100)
            setDirection('down')
        else setDirection('none')
    }

    return (
        <Content onScroll={handleScroll} sx={{
            '& .matter:nth-child(even)': {
                background: colors['cool-gray-100']
            }
        }}>
            {currentTab === 0 && tasks.taskList.map((task : any , index : number ) => {
                return (
                    <TaskItem
                        key={index}
                        onView={() => {
                            dispatch.tasks.setCurrentTask(task)
                            dispatch.home.openReportDialog(true)
                        }}
                        onEdit={() => {
                            dispatch.tasks.setCurrentTask(task)
                            dispatch.home.openReportDialog()
                        }}
                        id={-1}
                        taskName={task.matternumber}
                        status={
                            task.achievementrate === 100 ? 'finished' :
                                task.achievementrate === 0 ? 'not-reported' : 'draft'
                        }
                        reportedDate={dayjs(task.workdate).format('DD日 MM月 YYYY年')}
                        reportedTime={task.worktime}
                        progress={task.achievementrate}
                    />
                )
            })}
            {currentTab !== 0 && matters.map((matter: any) => {
                return (
                    <Stack
                        onClick={() => {
                            dispatch.tasks.setCurrentTask({
                                matternumber: matter.matternumber
                            })
                            dispatch.home.openReportDialog()
                        }}
                        padding={'8px 16px'} key={matter.matternumber} direction={'column'} gap={'8px'} className={'matter'}>
                        <Typography
                            variant={'sm'}
                            fontWeight={500}
                            color={colors["cool-gray-900"]}>
                            { matter.mattername }
                        </Typography>
                        <Typography
                            variant={'xs'}
                            fontWeight={400}
                            color={colors["cool-gray-500"]}>
                            { matter.matternumber }
                        </Typography>
                    </Stack>
                )
            })}
            <Switch2 direction={direction}/>
        </Content>
    )
}

const Content = styled(Box)({
    overflow: 'auto',
    height: 'calc(100vh - 88px)'
})

export default ReportList