import { createModel } from "@rematch/core";
import { doLogin } from "../services/apis/api.service";
import { RootModel } from "./index";

export type LoginStateType = {
    username: string,
    password: string,
    passwordInputType: 'text' | 'password',
    error: null | string
}

const initialState: LoginStateType = {
    username: '',
    password: '',
    passwordInputType: 'password',
    error: null
}

export const login = createModel<RootModel>()({
    name: 'login',
    state: initialState,
    reducers: {
        setField(state, { value, key }) {
            // @ts-ignore
            state[key] = value
        },
        setInputType(state, inputType: typeof initialState.passwordInputType) {
            state.passwordInputType = inputType
        },
        setError(state, error) {
            state.error = error
        }
    },
    effects: dispatch => {
        return {
            async submitLogin(_: any, rootState) {
                const data = await doLogin(rootState.login.username, rootState.login.password)

                if (data) {
                    await dispatch.login.setError(null)
                    await dispatch.auth.saveLoginInfo(data)

                    return true
                }

                await dispatch.login.setError('Error logging in')

                return false
            },
            logout() {
                dispatch.auth.logout()
            }
        }
    }
})