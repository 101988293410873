import {Box, styled, Typography} from "@mui/material"
import {DayCell} from "../molecules/DayCell";
import {getDaysForCalendar, getStartDateAndEndDateForSelectedMonth,} from "../../utils/calenderUtils";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import colors from './../../styles/colors'
import {getDayReport} from "../../services/apis/api.service";
import CalendarHeader from "./CalendarHeader";
import {DayAttr, mergeWithTasks, UNIT_MONTH} from "../../utils/dayUtils";
import Switch2 from "./Switch2";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import useMatters from "../../hooks/useMatters";

type CalendarProps = {}

const days: string[] = [
    '日',
    '月',
    '火',
    '水',
    '木',
    '金',
    '土',
]

export const MonthView = (_: CalendarProps) => {
    const currentTab = useSelector((root: RootState) => root.home.currentTab || 0)
    const code = useSelector((root: RootState) => root.auth.user?.code || 0)
    const [currentMonth, setCurrentMonth] = useState(dayjs())
    const [content, setContent] = useState<DayAttr[]>(getDaysForCalendar(currentMonth)) // all days for previous + current + next months
    const [loading, setLoading] = useState(false)
    const dayIndex = dayjs().day()  //  0 to 6
    const [direction, setDirection] = useState<'none' | 'down' | 'up'>('none')

    const { fetch } = useMatters((data) => {
        mergeTasks(data)
    })

    useEffect(() => {
        setContent(getDaysForCalendar(currentMonth))

        fetchDayReports(currentTab).then()
            .catch(() => {
                setContent(getDaysForCalendar(currentMonth))
            })
            .finally(() => {
                setLoading(false)
            })
        if (currentTab !== 0) { fetch() }

        // @ts-ignore
        window.reloadData = currentTab === 0 ? fetchDayReports : fetch
    }, [currentMonth, currentTab])

    const fetchDayReports = async (currentTab: number) => {
        setLoading(true)

        if (currentTab === 0) {
            let value = getStartDateAndEndDateForSelectedMonth(currentMonth)
            const response = await getDayReport({code: code, startDate: value.startDate, endDate: value.endDate})
            mergeTasks(response.data)
        }

        setLoading(false)
    }

    // add tasks to corresponding day
    const mergeTasks = (response: any) => {
        setContent(mergeWithTasks(getDaysForCalendar(currentMonth), response))
    }

    const toNextMonth = () => {
        if (loading) return
        const month = dayjs(currentMonth).add(1, UNIT_MONTH)
        setCurrentMonth(month)
    }

    const toPreviousMonth = () => {
        if (loading) return
        const month = dayjs(currentMonth).subtract(1, UNIT_MONTH)
        setCurrentMonth(month)
    }

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        if (event.currentTarget.scrollTop > 50)
            setDirection('down')
        else setDirection('none')
    }

    return (
        <Content>
            <CalendarHeader onNext={toNextMonth}
                            onPrevious={toPreviousMonth}
                            currentDay={dayjs().format('DD')}
                            date={currentMonth.format('YYYY年 MM月')}
                            loading={loading}/>
            <HeadRow>
                {
                    days.map((day: string, index: number) =>
                        <HeadCell key={index}>
                            <Typography variant={'sm'}
                                        fontWeight={700}
                                        color={dayIndex === index ? colors['blue-600'] : colors['cool-gray-600']}
                            >
                                {day}
                            </Typography>
                        </HeadCell>
                    )
                }
            </HeadRow>
            <DayContainer onScroll={handleScroll}>
                {
                    content.map((dayAttr: DayAttr, index: number) =>
                        <DayCell data={dayAttr} key={index}/>
                    )
                }
            </DayContainer>
            <Switch2 direction={direction}/>
        </Content>
    )
}

const Content = styled(Box)({
    display: 'grid',
    gridTemplateRows: 'repeat(2,min-content) 1fr',
    overflowY: 'scroll'
})

const HeadRow = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(7,1fr)',
    backgroundColor: '#F9FAFB',
    height: '36px',
    borderTop: '1px solid #E5E7EB'
})

const HeadCell = styled(Box)({
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center'
})


const DayContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(7,1fr)',
    overflow: 'scroll',
    paddingBottom: '64px'
})
