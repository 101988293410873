import {atomWithImmer} from "jotai-immer";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {useEffect} from "react";
import {useAtom} from "jotai";
import {getHttpClient} from "../services/apis/httpClient";

const mattersAtom = atomWithImmer<any[]>([])

const useMatters = (onLoad?: (d: any) => void) => {
    const [matters, setMatters] = useAtom(mattersAtom)
    const currentTab = useSelector((root: RootState) => root.home.currentTab)
    const request = getHttpClient()

    const fetch = () => {
        request({
            url: '/matters',
            method: 'GET'
        }).then(([res, error]) => {
            if (error) return
            if (res.status !== 200) return

            separateByDate(res.data)
            setMatters(res.data)
        })
    }

    useEffect(() => {
        if (currentTab === 0) return
        fetch()
    }, [currentTab])

    const separateByDate = (data: any[]) => {
        const separated: any[] = []

        for(let matter of data) {
            const keys = Object.keys(matter)
                .filter(key => key.startsWith('date'))
            for(let date of keys) {
                separated.push({
                    ...matter,
                    workdate: matter[date],
                    worktime: 0
                })
            }
        }

        onLoad?.(separated)
    }

    return {
        matters,
        fetch
    }
}

export default useMatters