import {SvgIcon, SvgIconProps, SxProps} from "@mui/material";
import React from "react";
import colors from "../../styles/colors";

type CalendarEmptyProps = {
    sx?: SxProps,
    fill?: keyof typeof colors
} & SvgIconProps
const CalendarEmpty: React.FC<CalendarEmptyProps> = ({ fill = 'cool-gray-900', sx, ...props }) => {
    return (
        <SvgIcon {...props} viewBox={'0 0 28 28'} sx={sx} >
            <path fill={colors[fill]} d="M25 9.5V21.75C25 23.5449 23.5449 25 21.75 25H6.25C4.45507 25 3 23.5449 3 21.75V9.5H25ZM21.75 3C23.5449 3 25 4.45507 25 6.25V8H3V6.25C3 4.45507 4.45507 3 6.25 3H21.75Z" />
        </SvgIcon>
    )
}

export default CalendarEmpty