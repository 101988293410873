import React from "react";
import {Stack, Typography} from "@mui/material";
import CalendarEmpty from "../icons/CalendarEmpty";

const MiniCalendar: React.FC<{date: string}> = ({date}) => {
    return (
        <Stack
            position={'relative'}
            width={'20px'}
            height={'20px'}
            sx={{background: 'white'}}>
            <CalendarEmpty sx={{
                fontSize: '20px'
            }} fill={'cool-gray-400'} onClick={() => {}}/>
            <Typography
                variant={'xs'}
                fontSize={'8px'}
                fontWeight={700}
                lineHeight={'8px'}
                color={'white'}
                sx={{
                    position: 'absolute',
                    bottom: '4px',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}>
                {date}
            </Typography>
        </Stack>
    )
}

export default MiniCalendar