import React, {useMemo} from "react";
import {Stack, TextField} from "@mui/material";
import ArrowLeft from "../../components/icons/ArrowLeft";
import DismissCircle from "../../components/icons/DismissCircle";
import elevations from "../../styles/elevations";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../store";
import TaskItem from "../../components/organisms/TaskItem";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

const Search: React.FC<any> = () => {
    const search = useSelector((root: RootState) => root.search)
    const dispatch = useDispatch<Dispatch>()
    const tasks = useSelector((root: RootState) => root.tasks)
    const navigate = useNavigate()
    const tasksList = useMemo(() => {
        if (search.searchKey.trim() === '') {
            return tasks.taskList
        }
        const query = new RegExp(`.*${search.searchKey.trim()}.*`)
        return tasks.taskList.filter((task) => {
            return query.test(task.matternumber)
        })
    }, [tasks.taskList, search.searchKey])
    const handleChange= (e: any) => {
        dispatch.search.changeSearchKey(e.target.value)
    }
    return (
        <Stack
            direction={'column'}
            padding={'0'}>
            <Stack
                direction={'row'}
                padding={'12px 16px'}
                gap={'16px'}
                alignItems={'center'}
                height={'62px'}
                boxShadow={elevations.y}
                sx={{background: 'white'}}>
                <ArrowLeft sx={{
                    fontSize: '24px',
                    cursor: 'pointer'
                }} fill={'cool-gray-400'} onClick={() => {
                    navigate('/')
                }}/>
                <TextField
                    variant={'outlined'}
                    onChange={handleChange}
                    value={search.searchKey}
                    fullWidth
                    InputProps={{
                        endAdornment: search.searchKey.length > 0 &&
                            <DismissCircle sx={{
                                fontSize: '20px',
                                cursor: 'pointer'
                            }} fill={'cool-gray-400'} onClick={() => {
                                dispatch.search.changeSearchKey('')
                            }}/>
                    }}
                />
            </Stack>
            <Stack
                direction={'column'}>
                {tasksList.map((task, index) => {
                    return (
                        <TaskItem
                            key={index}
                            onView={() => {
                                dispatch.tasks.setCurrentTask(task)
                                dispatch.home.openReportDialog()
                            }}
                            onEdit={() => {
                                dispatch.tasks.setCurrentTask(task)
                                dispatch.home.openReportDialog()
                            }}
                            id={-1}
                            taskName={task.matternumber}
                            status={
                                task.achievementrate === 100 ? 'finished' :
                                    task.achievementrate === 0 ? 'not-reported' : 'draft'
                            }
                            reportedDate={dayjs(task.workdate).format('DD日 MM月 YYYY年')}
                            reportedTime={task.worktime}
                            progress={task.achievementrate}
                        />
                    )})}
            </Stack>
        </Stack>
    )
}

export default Search