import {SvgIcon} from "@mui/material";

const ArrowSyncCircle = () => {
    return (
        <SvgIcon viewBox={'0 0 20 20'} sx={{fontSize: '20px'}} >
            <path d="M8.68569 2.85216L2.12696 14.7771C1.57714 15.7768 2.30038 17 3.44129 17H16.5587C17.6996 17 18.4229 15.7768 17.8731 14.7771L11.3143 2.85216C10.7444 1.81599 9.25558 1.816 8.68569 2.85216ZM10 6.75C10.4142 6.75 10.75 7.08579 10.75 7.5V11.5C10.75 11.9142 10.4142 12.25 10 12.25C9.58579 12.25 9.25 11.9142 9.25 11.5V7.5C9.25 7.08579 9.58579 6.75 10 6.75ZM10.75 13.75C10.75 14.1642 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.1642 9.25 13.75C9.25 13.3358 9.58579 13 10 13C10.4142 13 10.75 13.3358 10.75 13.75Z" fill="#D1D5DB"/>
        </SvgIcon>
    )
}

export default ArrowSyncCircle
