import React from "react";
import {Stack, Tab, Tabs} from "@mui/material";
import Search from "../icons/Search";
import Image from "../atoms/Image";
import SignOut from "../icons/SignOut";
import logo from "../../images/logo.svg";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../store";
import {useNavigate} from "react-router-dom";

const Navbar: React.FC<any> = () => {
    const home = useSelector((root: RootState) => root.home)
    const navigate = useNavigate()
    const dispatch = useDispatch<Dispatch>()

    const logout = () => {
        dispatch.login.logout()
    }
    return (
        <Stack
            direction={'column'}
            gap={'0px'}
            margin={'0px'}
            width={'inherit'}
            boxShadow={'0px 1px 0px #E4E4E7'}>
            <Stack
                direction={'row'}
                height={'40px'}
                alignItems={'center'}
                justifyContent={'space-between'}
                paddingLeft={'16px'}
                paddingRight={'16px'}>
                <Search sx={{
                    fontSize: '24px',
                    marginTop: '12px',
                    marginBottom: '4px',
                    cursor: 'pointer'
                }} fill={'cool-gray-500'} onClick={() => {navigate('/search')}} />
                <Image src={logo} sx={{marginBottom: '0px', width: '62px', height: '32px'}}/>
                <SignOut sx={{
                    fontSize: '24px',
                    marginTop: '12px',
                    marginBottom: '4px',
                    cursor: 'pointer'
                }} fill={'cool-gray-400'} onClick={logout} />
            </Stack>
            <Tabs value={home.currentTab} sx={{
                margin: '0 16px'
            }}>
                <Tab label={"日報"} onClick={() => dispatch.home.setTab(0)}/>

                <Tab label={"案件スケジュール"} onClick={() => dispatch.home.setTab(1)}/>

                <Tab label={"個人スケジュール"} onClick={() => dispatch.home.setTab(2)}/>
            </Tabs>
        </Stack>
    )
}

export default Navbar