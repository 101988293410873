import {Box, BoxProps, styled, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import colors from "../../styles/colors";
import dayjs from "dayjs";
import {blue} from "@mui/material/colors";
import CalendarHeader from "./CalendarHeader";
import {getDaysFor3DaysView, getStartDateAndEndDateForSelected3Day,} from "../../utils/threeDaysViewUtils";
import {getDayReport} from "../../services/apis/api.service";
import {DayAttr, mergeWithTasks, UNIT_DAY} from "../../utils/dayUtils";
import Switch2 from "./Switch2";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../store";
import useMatters from "../../hooks/useMatters";

const TIME_FORMAT = 'HH:00'

export const ThreeDaysView = () => {
    const dispatch = useDispatch<Dispatch>()
    const code = useSelector((root: RootState) => root.auth.user?.code || 0)
    const [currentDay, setCurrentDay] = useState(dayjs())
    const [content, setContent] = useState<DayAttr[]>(getDaysFor3DaysView(currentDay))
    const [loading, setLoading] = useState(false)
    const [direction, setDirection] = useState<'none' | 'down' | 'up'>('none')
    const currentTab = useSelector((root: RootState) => root.home.currentTab || 0)

    const { fetch } = useMatters((data) => {
        mergeTasks(data)
    })

    useEffect(() => {
        setContent(getDaysFor3DaysView(currentDay))

        fetchDayTasks().then()
            .catch(() => {
                setContent(getDaysFor3DaysView(currentDay))
            })
            .finally(() => {
                setLoading(false)
            })

        if (currentTab !== 0) { fetch() }

        // @ts-ignore
        window.reloadData = currentTab === 0 ? fetchDayTasks : fetch
    }, [currentDay, currentTab])

    const fetchDayTasks = async () => {
        setLoading(true)

        if (currentTab === 0) {
            let value = getStartDateAndEndDateForSelected3Day(currentDay)
            const response = await getDayReport({code: code, startDate: value.startDate, endDate: value.endDate})
            mergeTasks(response.data)
        }

        setLoading(false)
    }

    const mergeTasks = (response: any) => {
        setContent(mergeWithTasks(getDaysFor3DaysView(currentDay), response))
    }

    const toNextThreeDays = () => {
        if (loading) return
        const nextDay = dayjs(currentDay).add(2, UNIT_DAY)
        setCurrentDay(nextDay)
    }

    const toPreviousThreeDays = () => {
        if (loading) return
        const previousDay = dayjs(currentDay).subtract(2, UNIT_DAY)
        setCurrentDay(previousDay)
    }

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        if (event.currentTarget.scrollTop > 100)
            setDirection('down')
        else setDirection('none')
    }


    return (
        <Content>
            <CalendarHeader onNext={toNextThreeDays}
                            onPrevious={toPreviousThreeDays}
                            currentDay={dayjs().format('DD')}
                            date={currentDay.format('YYYY年 MM月')}
                            loading={loading}/>
            <HeadRow>
                <Box boxShadow={'0.5px 0px 0px 0px #E5E7EB'}/>
                {
                    content.map((dayAttr: DayAttr, index: number) =>
                        <HeadCell key={index} isToday={dayAttr.isToday}>
                            {
                                dayAttr.isToday ?
                                    <HeadTodayTypo>{dayAttr.day}</HeadTodayTypo> :
                                    <HeadTypo>{dayAttr.day}</HeadTypo>
                            }
                        </HeadCell>
                    )
                }
            </HeadRow>

            <TaskContainer onScroll={handleScroll}>
                <TimeColumn>
                    {
                        (new Array(24)).fill(1).map((_, index) => {
                            return (
                                <TimeCell key={index}>
                                    <Typography variant={'xs'} color={colors["cool-gray-500"]} lineHeight={'0.5rem'}>
                                        {dayjs().set('hour', (index + 1)).format(TIME_FORMAT)}
                                    </Typography>
                                </TimeCell>
                            )
                        })
                    }
                </TimeColumn>
                {
                    content.map((dayAttr: DayAttr, index: number) =>
                        <TaskColumn isToday={dayAttr.isToday} key={index}>
                            {
                                dayAttr.tasks.map(task =>
                                    <TaskViewContainer
                                        onClick={() => {
                                            dispatch.tasks.setCurrentTask(task.taskResponse)
                                            dispatch.home.openReportDialog()
                                        }}
                                        gridRow={`${task.startSpan} / span ${task.endSpan}`} key={index}>
                                        <Box bgcolor={blue[200]} borderRadius={'4px'}>
                                            <TaskTypo>{task.name}</TaskTypo>
                                        </Box>
                                    </TaskViewContainer>
                                )
                            }
                        </TaskColumn>
                    )
                }
            </TaskContainer>
            <Switch2 direction={direction}/>
        </Content>
    )
}

const HEAD_BOX_WIDTH = '56px'
const HEAD_BOX_HEIGHT = '32px'
const BODY_BOX_HEIGHT = '25px'
const TIME_BOX_HEIGHT = '50px'  // 2 times of BODY_BOX_HEIGHT

const Content = styled(Box)({
    display: 'grid',
    gridTemplateRows: 'repeat(2,min-content) 1fr',
    overflowY: 'scroll',
    height: 'calc(100vh - 126px)'
})

const HeadRow = styled(Box)({
    display: 'grid',
    gridTemplateColumns: `${HEAD_BOX_WIDTH} repeat(3,1fr)`,
    height: `${HEAD_BOX_HEIGHT}`,
    borderTop: '0.5px solid #E5E7EB',
    boxShadow: '0px 0.5px 0px 0px #E5E7EB',
})

const HeadCell = styled(Box)<{ isToday: boolean }>(props => ({
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    background: props.isToday ? colors['blue-50'] : colors['white'],
    boxShadow: 'inset 0.5px 0.5px 0px 0px #e5e7eb',
}))

const HeadTypo = styled(Typography)({
    fontSize: '12px',
    fontWeight: 400,
    color: colors['cool-gray-900']
})

const HeadTodayTypo = styled(Typography)({
    fontSize: '12px',
    fontWeight: 400,
    color: colors['white'],
    backgroundColor: colors['blue-600'],
    textAlign: 'center',
    borderRadius: '12px',
    padding: '2px 6px'
})

const TaskTypo = styled(Box)({
    color: colors['blue-600'],
    fontSize: '12px',
    fontWeight: 400,
    marginTop: 'auto',
    marginBottom: 'auto'
})

const TaskContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: `${HEAD_BOX_WIDTH} repeat(3, 1fr)`,
    overflow: 'scroll',
    paddingBottom: '64px'
})

const TimeColumn = styled(Box)({
    display: 'grid',
    gridTemplateRows: `repeat(24,${TIME_BOX_HEIGHT})`,
    justifyContent: 'center',
    alignContent: 'center',
    boxShadow: '0.5px 0px 0px 0px #E5E7EB',
})

const TimeCell = styled(Box)({
    display: 'grid',
    alignItems: 'end'
})

const TaskColumn = styled(Box)<{ isToday: boolean }>(props => ({
    display: 'grid',
    gridTemplateRows: `repeat(24,${BODY_BOX_HEIGHT})`,
    background: props.isToday ? colors['blue-50'] : colors['white'],
    boxShadow: 'inset 0.5px 0.5px 0px 0px #e5e7eb',
}))

const TaskViewContainer = styled(Box)<BoxProps>({
    display: 'grid',
    boxShadow: '0px 0.5px 0px 0px #E5E7EB',
    padding: '2px'
    // gridRow: 'span 3'
})
