import _ from "lodash";

export type TaskAttr = {
    name: string
    state: number
    startSpan: number
    endSpan: number
    taskResponse?: any     // json from api to open dialog
}

export type DayAttr = {
    dayIndex: number
    day: string
    isToday: boolean
    tasks: TaskAttr[]
    date: string   // formatted date
    dayInThisMonth: boolean    // check whether this day is in this month or not,
}

export const TIME_FORMAT = 'YYYYMMDD'
export const TIME_FORMAT_2 = 'MMM DD'
export const TEMPLATE_D = 'D'
export const UNIT_DATE = 'date'
export const UNIT_MONTH = 'month'
export const UNIT_DAY = 'day'

export function groupTaskWithDate(response: any[]) {
    return _.groupBy(response, res => res.workdate)
}

export function mergeWithTasks(days: DayAttr[], response: any[]): DayAttr[] {
    if (response.length === 0)
        return days;
    const tasks = groupTaskWithDate(response)   // lodash.js
    return days.map(r => {
        if (tasks[r.date]) {
            r.tasks = tasks[r.date].map(value => {
                return {
                    taskResponse: value,
                    name: value.matternumber,
                    state: 1,
                    startSpan: value.worktime * 2 + 1,
                    endSpan: 2
                }
            })
        }
        return r
    })
}


export const fakeResponse = [
    {
        "code": 2,
        "linenumber": 1,
        "matternumber": "K180213",
        "workdepartment": 1,
        "workdepartmentname": "機械",
        "workclassification": 1,
        "workclassificationname": "構想設計",
        "workcontents": 3,
        "workcontentsname": "粗構成ﾚｲｱｳﾄ",
        "workdate": "20220929",
        "worktime": 1.0,
        "achievementrate": 40
    },
    {
        "code": 2,
        "linenumber": 2,
        "matternumber": "K180010",
        "workdepartment": 1,
        "workdepartmentname": "機械",
        "workclassification": 1,
        "workclassificationname": "構想設計",
        "workcontents": 1,
        "workcontentsname": "ｼｽﾃﾑ構成図",
        "workdate": "20220929",
        "worktime": 16.0,
        "achievementrate": 40
    },
    {
        "code": 2,
        "linenumber": 1,
        "matternumber": "K1999993",
        "workdepartment": 1,
        "workdepartmentname": "機械",
        "workclassification": 1,
        "workclassificationname": "構想設計",
        "workcontents": 3,
        "workcontentsname": "粗構成ﾚｲｱｳﾄ",
        "workdate": "20220930",
        "worktime": 8.0,
        "achievementrate": 40
    },
    {
        "code": 2,
        "linenumber": 3,
        "matternumber": "K180213",
        "workdepartment": 1,
        "workdepartmentname": "機械",
        "workclassification": 1,
        "workclassificationname": "構想設計",
        "workcontents": 3,
        "workcontentsname": "粗構成ﾚｲｱｳﾄ",
        "workdate": "20220827",
        "worktime": 8.0,
        "achievementrate": 40
    },
    {
        "code": 21,
        "linenumber": 3,
        "matternumber": "K1048575",
        "workdepartment": 1,
        "workdepartmentname": "機械",
        "workclassification": 1,
        "workclassificationname": "構想設計",
        "workcontents": 3,
        "workcontentsname": "粗構成ﾚｲｱｳﾄ",
        "workdate": "20221130",
        "worktime": 8.0,
        "achievementrate": 40
    }
]