import React from "react";
import {Stack, Typography} from "@mui/material";
import Arrow from "../icons/Arrow";
import ArrowSyncCircle from "../atoms/ArrowSyncCircle";
import CheckmarkCircle from "../atoms/CheckmarkCircle";
import Warning from "../atoms/Warning";
import Dot from "../atoms/Dot";
import colors from "../../styles/colors";

export type TaskItemProps = {
    id: number,
    taskName: string,
    status: 'finished' | 'not-reported' | 'draft',
    reportedDate?: string,
    reportedTime?: string,
    progress?: number,
    onView: () => void,
    onEdit: () => void
}

const TaskItem: React.FC<TaskItemProps> = ({ onView, onEdit, taskName, status, reportedDate, reportedTime, progress}) => {
    const handleIconClick = (e: any) => {
        e.stopPropagation()
        onEdit()
    }

    return (
        <Stack
            onClick={onView}
            height={'64px'}
            direction={'row'}
            alignItems={'center'}
            padding={'0px 16px'}
            sx={{cursor: 'pointer'}}
            justifyContent={'space-between'}>
            <Stack
                direction={'column'}
                gap={'4px'}>
                <Typography
                    variant={'sm'}
                    fontWeight={500}
                    color={colors["cool-gray-900"]}>
                    {taskName}
                </Typography>
                <Stack
                    direction={'row'}
                    gap={'8px'}
                    alignItems={'center'}>
                    {status === 'draft' && <ArrowSyncCircle />}
                    {status === 'finished' && <CheckmarkCircle />}
                    {status === 'not-reported' && <Warning />}
                    {status === 'not-reported' &&
                    <Typography
                        variant={'sm'}
                        fontWeight={400}
                        color={colors["cool-gray-500"]}>
                        Not reported yet
                    </Typography>}
                    {status !== 'not-reported' &&
                        <>
                            <Typography
                                variant={'sm'}
                                fontWeight={400}
                                color={colors["cool-gray-500"]}>
                                {reportedDate}
                            </Typography>
                            <Dot />
                            <Typography
                                variant={'sm'}
                                fontWeight={400}
                                color={colors["cool-gray-500"]}>
                                {reportedTime}
                            </Typography>
                            <Dot />
                            <Typography
                                variant={'sm'}
                                fontWeight={400}
                                color={colors["cool-gray-500"]}>
                                {progress}%
                            </Typography>
                        </>
                    }
                </Stack>
            </Stack>
            <Arrow
                onClick={handleIconClick}
                sx={{
                fontSize: '16px',
                cursor: 'pointer'
            }} fill={'cool-gray-400'} />
        </Stack>
    )
}

export default TaskItem