import dayjs from "dayjs";

export const fakeTaskList = (count: number) => {
    const statuses = ['draft', 'finished', 'not-reported'],
        progresses = [30, 20, 100, 40]
    return Array.from({ length: count}).map((_, index) => ({
        id: index + 1,
        name: 'K180002 **********装置',
        status: statuses[Math.floor(Math.random() * 3)],
        date: dayjs(),
        duration: '3h',
        progress: progresses[Math.floor(Math.random() * 4)]
    }))
}

export class TasksService {
    constructor() {}

    getTaskList(viewType: string) : any[] {
        console.log('Load task list for viewType: ', viewType)
        return fakeTaskList(50)
    }

    searchTask(keyword: string): any[] {
        console.log('Search: ', keyword)
        return fakeTaskList(20)
    }
}