import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {Dispatch, RootState} from '../../store'
import {ViewType} from "../../models/home";
import {MonthView} from "../../components/organisms/MonthView";
import {ThreeDaysView} from "../../components/organisms/ThreeDaysView";
import {WeekView} from "../../components/organisms/WeekView";
import ReportList from "./sections/ReportList";
import {Box, styled} from "@mui/material";
import Navbar from "../../components/organisms/Navbar";

const Home: React.FC<any> = () => {
    const {viewType} = useSelector((root: RootState) => root.home)

    return (
        <Content>
            <Navbar/>
            {/*<TaskTypeChooser/>*/}
            {
                viewType == ViewType.LIST && <ReportList/>
            }
            {
                viewType === ViewType.MONTH && <MonthView/>
            }
            {
                viewType === ViewType.THREEDAYS && <ThreeDaysView/>
            }
            {
                viewType === ViewType.DAY && <WeekView/>
            }
        </Content>
    )
}

const Content = styled(Box)({
    display : 'grid',
    gridTemplateRows : 'repeat(2,min-content) 1fr',
    height: '100vh',
    overflow: 'hidden'
})

export default Home