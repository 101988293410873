import dayjs from "dayjs";
import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import {fakeTaskList} from "../services/tasks.service";

export type TaskChooserStateType = {
    date: dayjs.Dayjs,
    startedTime: string,
    finishedTime: string,
    tasks: any[],
    open: boolean
}

const initialState: TaskChooserStateType = {
    date: dayjs(),
    startedTime: '1:00',
    finishedTime: '2:00',
    tasks: fakeTaskList(2),
    open: false
}

export const taskChooser = createModel<RootModel>() ({
    name: 'taskChooser',
    state: initialState,
    reducers: {
        open(state, data: Omit<TaskChooserStateType, 'open'>) {
            state.open = true

            state.date = data.date
            state.startedTime = data.startedTime
            state.finishedTime = data.finishedTime
            state.tasks = data.tasks
        },
        close(state) {
            state.open = false
        }
    }
})
