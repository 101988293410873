import dayjs, {Dayjs} from "dayjs";
import {DayAttr, TaskAttr, TIME_FORMAT, TIME_FORMAT_2, UNIT_DAY} from "./dayUtils";

// date is current day
// return previous day + current day + next day
export function getDaysFor3DaysView(date: Dayjs): DayAttr[] {
    let days: DayAttr[] = []
    const previousDay = dayjs(date).subtract(1, UNIT_DAY)
    days.push({
        dayIndex: -1,   // ignore
        day: previousDay.format(TIME_FORMAT_2),
        isToday: previousDay.isToday(),
        date: previousDay.format(TIME_FORMAT),
        dayInThisMonth: false,
        tasks: <TaskAttr[]>[]
    })
    days.push({
        dayIndex: -1,   // ignore
        day: date.format(TIME_FORMAT_2),
        isToday: date.isToday(),
        date: date.format(TIME_FORMAT),
        dayInThisMonth: false,
        tasks: <TaskAttr[]>[]
    })
    const nextDay = dayjs(date).add(1, UNIT_DAY)
    days.push({
        dayIndex: -1,   // ignore
        day: nextDay.format(TIME_FORMAT_2),
        isToday: nextDay.isToday(),
        date: nextDay.format(TIME_FORMAT),
        dayInThisMonth: false,
        tasks: <TaskAttr[]>[]
    })

    return days

}


export function getStartDateAndEndDateForSelected3Day(currentDay: Dayjs): { startDate: string, endDate: string } {
    const previousDay = dayjs(currentDay).subtract(1, UNIT_DAY)
    const nextDay = dayjs(currentDay).add(1, UNIT_DAY)
    return {
        startDate: previousDay.format(TIME_FORMAT),
        endDate: nextDay.format(TIME_FORMAT),
    }

}

export function getDaysForOneDayView(date: Dayjs): DayAttr[] {
    let days: DayAttr[] = []
    days.push({
        dayIndex: -1,   // ignore
        day: date.format(TIME_FORMAT_2),
        isToday: date.isToday(),
        date: date.format(TIME_FORMAT),
        dayInThisMonth: false,
        tasks: <TaskAttr[]>[]
    })
    return days
}

export function getDaysHeaderForOneDayView(date: Dayjs): { dayIndex: string, isToday: boolean, selectedDay: boolean }[] {
    let days: { dayIndex: string, isToday: boolean, selectedDay: boolean }[] = []
    // previous 3 days
    for (let i = 3; i > 0; i--) {
        let d = dayjs(date).subtract(i, UNIT_DAY)
        days.push({
            dayIndex: d.format('D'),
            isToday: d.isToday(),
            selectedDay: false
        })
    }
    days.push({
        dayIndex: date.format('D'),
        isToday: date.isToday(),
        selectedDay: true
    })
    // next 3 days
    for (let i = 1; i <= 3; i++) {
        let d = dayjs(date).add(i, UNIT_DAY)
        days.push({
            dayIndex: d.format('D'),
            isToday: d.isToday(),
            selectedDay: false
        })
    }
    return days
}


export function getStartDateAndEndDateForSelectedDay(currentDay: Dayjs): { startDate: string, endDate: string } {
    const nextDay = dayjs(currentDay).add(1, UNIT_DAY)
    return {
        startDate: currentDay.format(TIME_FORMAT),
        endDate: nextDay.format(TIME_FORMAT),
    }

}
